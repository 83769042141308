<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Перелік банківських рахунків
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <MainModal
            :main="{ component: 'CONTRACTOR_BANK_ACCOUNT_ELEMENTS', title: 'Банківський рахунок контрагента' }"
            :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
            :item="{
                      person_hash: flat.person_hash,
                      contractor_name: flat.full_address,
                    }"
            @updateItemModal="updateItemModal"
        ></MainModal>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="tableHeaders"
        :items="items"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="openModal"
    >
      <template v-slot:item.icon="{ item }">
        <MainModal
            :main="{ component: 'CONTRACTOR_BANK_ACCOUNT_ELEMENTS', title: 'Банківський рахунок контрагента' }"
            :button="{
                      iconActivator: true,
                      class: 'mr-2',
                      color: 'success',
                      buttonIcon: 'mdi-playlist-check',
                      iconSize: 26
                     }"
            :item="{
                      id: item.id,
                      contractor_name: flat.full_address,
                      contractor_id: item.contractor_id,
                      bank_id: item.bank_id,
                      account: item.account,
                      print_name: item.print_name,
                      main: item.main,
                      code: item.code,
                    }"
            :modal="dialog_id === item.id"
            @updateItemModal="updateItemModal"
        />
      </template>
      <template v-slot:item.main="{ item }">
        <v-simple-checkbox v-model="item.main" disabled :ripple="false"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapGetters, mapActions} from "vuex";
import {GET_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS} from "@/store/actions/accounting/list";
import {getAccountIcon, getCategoryName} from "@/utils/icons";
import {categoriesSelect} from "@/utils/icons"

export default {
  name: "HWL_FlatContractorBankAccount",
  props: {},
  components: { MainModal },
  data() {
    return {
      categoriesSelect,
      dialog_id: 0,
      tableHeaders: [
        { text: '', value: 'icon', width: 20 },
        { text: 'Найменування', value: 'print_name' },
        { text: '№ рахунку', value: 'account' },
        { text: 'Банк', value: 'bank_name' },
        { text: 'Основний', value: 'main' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'get_contractor_bank_account_list',
      flat: 'getLegacyFlat'
    })
  },
  methods: {
    ...mapActions({
      fetch_items: GET_CONTRACTOR_BANK_ACCOUNT_LIST_ELEMENTS
    }),
    getAccountIcon,
    getCategoryName,
    updateItemModal() {
      this.dialog_id = 0
    },
    openModal(row) {
      this.dialog_id = row.id
    },
  },
  created() {
    this.fetch_items({person_hash: this.flat.person_hash})
  }
}
</script>

<style scoped lang="scss">

</style>